export default {
    state: {
        CPICTaskCount: 0,
        ECIFTaskCount: 0,
        // 服务类型字典
        serviceTypeList: [
            // // 太保专用
            // {label: "现场修理", value: "01"},
            // {label: "路边维修-派送油料", value: "02"},
            // {label: "路边维修-电瓶充电", value: "03"},
            // {label: "路边维修-换胎", value: "04"},
            // {label: "路边维修-添加防冻液", value: "05"},
            // {label: "拖车牵引", value: "06"},
            // {label: "困境拖吊", value: "07"},
            // {label: "其他", value: "08"},
            // {label: "信息咨询（医疗/车辆技术/信息传递）", value: "09"},
            // {label: "信息咨询-紧急信息传递", value: "10"},
            // {label: "信息资源-医疗转介", value: "11"},
            // {label: "路边维修-含路边小修", value: "12"},
            // {label: "路边维修-开锁", value: "13"},
            // {label: "故障拖车（100公里免费）", value: "14"},
            // {label: "事故拖车（50公里免费）", value: "15"},
            // {label: "困境救援（吊车及专业施救）", value: "16"},
            // {label: "异地救援拖车后续利益服务（酒店住宿、继续旅游等）", value: "17"},
            // {label: "居住地事故拖车救援后出租车服务", value: "18"},
            // {label: "故障拖车（50公里免费）", value: "19"},
            // {label: "路边维修（微信渠道）", value: "20"},
            // {label: "地库救援 ", value: "21"},
            // {label: "高架救援", value: "22"},
            // {label: "自救授权", value: "23"},
            // // 国寿专用
            // {label: "换胎", value: "200000000004"},
            // {label: "50拖车", value: "200000000001"},
            // {label: "送油", value: "200000000005"},
            // {label: "吊车", value: "200000000009"},
            // {label: "充气", value: "200000000010"},
            // {label: "搭电", value: "200000000003"},
            // {label: "地库", value: "200000000008"},
            // {label: "小修", value: "200000000006"},
            // {label: "困境", value: "200000000007"},
            // {label: "送水", value: "200000000011"},
        ],
        // 工单类型字典
        statusList: [
            {label: "等待确认", value: "WAITING"},
            {label: "已阅读或已接单", value: "READ"},
            {label: "已建立案件", value: "CONTACTED"},
            {label: "已派单或已派工", value: "DISPATCHED"},
            {label: "待重派单", value: "REDISPATCHING"},
            {label: "已到达，开始救援", value: "ARRIVED"},
            {label: "工单已完成", value: "COMPLETED"},
            {label: "工单异常结束", value: "FAILED"},
            {label: "已取消，收费", value: "CANCELLED"},
            {label: "已取消，免费", value: "CANCELLED_FREE"},
            {label: "空驶取消", value: "CANCELLED_EMPTY"},
            {label: "派出前取消", value: "CANCELLED_BEFORE_DISPATCHING"},
            {label: "派出后取消", value: "CANCELLED_AFTER_DISPATCHING"},
            {label: "太保取消", value: "CPIC_CANCELLED"},
            {label: "无需派出-线上咨询解决", value: "REPAIR_OVER_THE_PHONE"},
            {label: "授权客户自救报销", value: "SELF_ASSIST_AND_REIMBURSE"},
            {label: "咨询", value: "TELEPHONE_COUNSELING"},
            {label: "客人自费救援", value: "GUESTS_AT_THEIR_OWN_EXPENSE_RESCUE"},
            {label: "案件重复", value: "DUPLICATE_TICKET"},
            {label: "自救报销支付", value: "PAY_FOR_SELF_ASSIST"},
            {label: "搭电不成功未换电瓶", value: "ELECTRIFY_FAIL_BATTERY_NO_REPLACED"},
            {label: "搭电不成功已换电瓶", value: "ELECTRIFY_FAIL_BATTERY__REPLACED"},
            {label: "搭电成功未换电瓶", value: "ELECTRIFY_SUCCESS_BATTERY_NO_REPLACED"},
            {label: "搭电成功已换电瓶", value: "ELECTRIFY_SUCCESS_BATTERY__REPLACED"},
            {label: "已结算", value: "SETTLE"},
        ],
        // 车辆类型字典
        carTypeList: [
            {label: "小型救援车", value: "SMALL"},
            {label: "重型拖车", value: "HEAVY"},
            {label: "平板拖车", value: "FLATBED"},
        ]
    },
    getters: {
        geetServiceTypeList(state) {
            return state.serviceTypeList
        },
        getCPICTaskCount(state) {
            return state.CPICTaskCount
        },
        getECIFTaskCount(state) {
            return state.ECIFTaskCount
        }
    },
    mutations: {
        setServiceTypeList(state, value) {
            state.serviceTypeList = value
        },
        setCPICTaskCount(state, value) {
            state.CPICTaskCount = value
        },
        setECIFTaskCount(state, value) {
            state.ECIFTaskCount = value
        },
    },
    action: {}
}