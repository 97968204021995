import axios from "axios";
import {getCookie} from "@/utils/storage.js";
import router from "@/router/index";
import store from "@/store/index";
import {ElMessage, ElMessageBox} from "element-plus"

// 路由拦截
axios.interceptors.request.use(function (config) {
    config.headers["Authorization"] = `Bearer ${getCookie("token")}`
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(
    response => {
        if (response.data.status === 401) {
            store.commit("delToken");
            store.commit("delUserData");
            // ElMessage({
            //     message: "登陆状态失效，请重新登录！",
            //     type: "error"
            // });
            router.push({name: "AuthLogin"});
        }
        return response;
    },
    error => {
        if (error.response) {
            return Promise.reject(error.response) // 返回接口返回的错误信息
        }
    });

// 请求封装
export function post(url, params, callback) {
    axios({
        method: "post",
        url: url,
        data: params,
    }).then(res => {
        callback(res);
    }).catch(err => {
        callback(err);
    })
}

export function del(url, params, callback) {
    axios({
        method: "delete",
        url: url,
        data: params,
    }).then(res => {
        callback(res);
    }).catch(err => {
        callback(err);
    })
}

export function get(url, params, callback) {
    axios({
        method: "get",
        url: url,
        params: params,
    }).then(res => {
        callback(res);
    }).catch(err => {
        callback(err);
    })
}