import {getCookie, getLocalStorage, setCookie, setLocalStorage} from "@/utils/storage";
import {clearLocalStorage, delCookie} from "../../utils/storage";

export default {
    state: {
        token: getCookie("token") ? getCookie("token") : "",
        userData: getLocalStorage("userData") ? getLocalStorage("userData") : ""
    },
    getters: {
        getUserData(state) {
            return state.userData
        }
    },
    mutations: {
        setToken(state, value) {
            state.token = value
            setCookie("token", value, 2592000);
        },
        setUserData(state, value) {
            state.userData = value
            setLocalStorage("userData", value)
        },
        delToken(state) {
            state.token = ""
            delCookie("token");
        },
        delUserData(state) {
            state.userData = ""
            clearLocalStorage("userData");
        }
    },
    action: {}
}