//http
import {del, get, post} from "./httpEngine.js";

// 接口地址
// let kUrlPrefix = "https://jiuyuan.demo.qoomoe.com"
let kUrlPrefix = ""

// 登录
export function LOGIN(params, callback) {
    post(kUrlPrefix + "/api/v1/auth/login", params, function (res) {
        callback(res);
    });
}

// 获取部门列表
export function GET_DEPARTMENTS_LIST(params, callback) {
    get(kUrlPrefix + "/api/v1/departments", params, function (res) {
        callback(res);
    });
}

// 新增职位
export function ADD_ROLES(params, callback) {
    post(kUrlPrefix + "/api/v1/roles", params, function (res) {
        callback(res);
    });
}

// 获取职位列表
export function GET_ROLES_LIST(params, callback) {
    get(kUrlPrefix + "/api/v1/roles", params, function (res) {
        callback(res);
    });
}

// 获取职位详情
export function GET_ROLES_DETAIL(id, callback) {
    get(kUrlPrefix + "/api/v1/roles/" + id, null, function (res) {
        callback(res);
    });
}

// 编辑职位
export function UPDATE_ROLES_DETAIL(params, callback) {
    post(kUrlPrefix + "/api/v1/roles/" + params.id, params, function (res) {
        callback(res);
    });
}

// 删除职位
export function DEL_ROLES_DETAIL(params, callback) {
    del(kUrlPrefix + "/api/v1/roles/", params, function (res) {
        callback(res);
    });
}

// 获取权限列表
export function GET_PERMISSIONS_LIST(params, callback) {
    get(kUrlPrefix + "/api/v1/permissions", params, function (res) {
        callback(res);
    });
}

// 获取用户列表
export function GET_USER_LIST(params, callback) {
    get(kUrlPrefix + "/api/v1/users", params, function (res) {
        callback(res);
    });
}

// 获取用户详情
export function GET_USER_DETAIL(id, callback) {
    get(kUrlPrefix + "/api/v1/users/" + id, null, function (res) {
        callback(res);
    });
}

// 新增用户
export function ADD_USER_DETAIL(params, callback) {
    post(kUrlPrefix + "/api/v1/users", params, function (res) {
        callback(res);
    });
}

// 修改用户
export function UPDATE_USER_DETAIL(params, callback) {
    post(kUrlPrefix + "/api/v1/users/" + params.id, params, function (res) {
        callback(res);
    });
}

// 删除用户
export function DEL_USERS(params, callback) {
    del(kUrlPrefix + "/api/v1/users", params, function (res) {
        callback(res);
    });
}

// 获取工单列表
export function GET_TICKETS_LIST(params, callback) {
    get(kUrlPrefix + "/api/v1/tickets", params, function (res) {
        callback(res);
    });
}

// 获取工单详情
export function GET_TICKETS_DETAIL(id, callback) {
    get(kUrlPrefix + "/api/v1/tickets/" + id, null, function (res) {
        callback(res);
    });
}

// 新增工单
export function ADD_TICKETS(params, callback) {
    post(kUrlPrefix + "/api/v1/tickets", params, function (res) {
        callback(res);
    });
}

// 修改工单
export function UPDATE_TICKETS(params, callback) {
    post(kUrlPrefix + "/api/v1/tickets/" + params.id, params, function (res) {
        callback(res);
    });
}

// 修改工单服务类型
export function UPDATE_SERVICE_TYPE(id, params, callback) {
    post(kUrlPrefix + "/api/v1/ticketsType/" + id, params, function (res) {
        callback(res);
    });
}

// 删除工单
export function DEL_TICKETS(params, callback) {
    del(kUrlPrefix + "/api/v1/tickets", params, function (res) {
        callback(res);
    });
}

// 修改工单状态
export function UPDATE_TICKETS_STATUS(params, callback) {
    post(kUrlPrefix + "/api/v1/ticket/status", params, function (res) {
        callback(res);
    });
}

// 取消工单
export function CANCEL_TICKETS_STATUS(params, callback) {
    post(kUrlPrefix + "/api/v1/ticketsCancel/" + params.id, params, function (res) {
        callback(res);
    });
}

// 派单
export function ADD_TICKETS_TASK(params, callback) {
    post(kUrlPrefix + " /api/v1/ticket/task", params, function (res) {
        callback(res);
    });
}

// 获取技师列表
export function GET_TECHNICIAN_LIST(params, callback) {
    get(kUrlPrefix + "/api/v1/technicians", params, function (res) {
        callback(res);
    });
}

// 获取车辆列表
export function GET_CAR_LIST(params, callback) {
    get(kUrlPrefix + "/api/v1/cars", params, function (res) {
        callback(res);
    });
}

// 获取车辆详情
export function GET_CAR_DETAIL(id, callback) {
    get(kUrlPrefix + "/api/v1/cars/" + id, null, function (res) {
        callback(res);
    });
}

// 新增车辆
export function ADD_CAR_LIST(params, callback) {
    post(kUrlPrefix + "/api/v1/cars", params, function (res) {
        callback(res);
    });
}

// 修改车辆
export function UPDATE_CAR_LIST(params, callback) {
    post(kUrlPrefix + "/api/v1/cars/" + params.id, params, function (res) {
        callback(res);
    });
}

// 删除车辆
export function DEL_CARS(params, callback) {
    del(kUrlPrefix + "/api/v1/cars", params, function (res) {
        callback(res);
    });
}

// 查看轨迹
export function GET_LOCATIONS(params, callback) {
    get(kUrlPrefix + "/api/v1/locations", params, function (res) {
        callback(res);
    });
}

// 查看图片/文件
export function GET_FILES(params, callback) {
    get(kUrlPrefix + "/api/v1/files", params, function (res) {
        callback(res);
    });
}

// 上传文件
export function UPLOAD_FILES(params, callback) {
    post(kUrlPrefix + " /api/v1/files/upload", params, function (res) {
        callback(res);
    });
}

// 回传
export function PUSH_FILES(params, callback) {
    post(kUrlPrefix + "/api/v1/task/pushFiles?id=" + params.id, null, function (res) {
        callback(res);
    });
}

// 获取服务类型字典
export function GET_SERVICE_LIST(params, callback) {
    post(kUrlPrefix + "/api/v1/serviceItems", params, function (res) {
        callback(res);
    });
}

// 工单结算
export function ORDER_SETTLE(params, callback) {
    post(kUrlPrefix + "/api/v1/settle/" + params.id, params, function (res) {
        callback(res);
    });
}