<template>
    <router-view></router-view>
</template>

<script>

import {GET_SERVICE_LIST} from "./api/dataProvider";
import {onMounted} from "vue";
import {useStore} from "vuex";

export default {
    name: "app",
    setup() {
        let $store = useStore()

        // 获取服务类型
        let getServiceList = () => {
            GET_SERVICE_LIST(
                {
                    pageSize: 100
                },
                (res) => {
                    let list = []
                    res.data.data.map((item) => {
                        list.push({
                            label: item.name,
                            value: item.value,
                            platform: item.platform
                        })
                    })
                    $store.commit("setServiceTypeList", list)
                }
            )
        }

        onMounted(() => {
            getServiceList()
        })
    }
};
</script>

<style lang="scss">
@import "./assets/style/style.css";
</style>