export default [
    // 登录
    {
        path: "/auth/login",
        name: "AuthLogin",
        component: () => import("../views/Auth/Login.vue"),
    },
    // 入口
    {
        path: "/",
        redirect: "/user/role/list",
        name: "Layout",
        component: () => import("../views/Layout/Layout.vue"),
        children: [
            {
                path: "/user/role/list",
                name: "UserRoleList",
                meta: {
                    breadcrumb: ["行政人事", "职位管理"]
                },
                component: () => import("../views/User/Role/UserRoleList.vue"),
            },
            {
                path: "/user/member/list",
                name: "UserMemberList",
                meta: {
                    breadcrumb: ["行政人事", "员工管理"]
                },
                component: () => import("../views/User/Member/UserMemberList.vue"),
            },
            {
                path: "/task/cpic/list",
                name: "TaskWorkOrderList",
                meta: {
                    breadcrumb: ["任务管理", "工单管理", "太保工单"]
                },
                component: () => import("../views/Task/WorkOrder/TaskWorkOrderList.vue"),
            },
            {
                path: "/task/ecif/list",
                name: "ECIFTaskWorkOrderList",
                meta: {
                    breadcrumb: ["任务管理", "工单管理", "国寿工单"]
                },
                component: () => import("../views/Task/ECIF/TaskWorkOrderList.vue"),
            },
            {
                path: "/technician/list",
                name: "TechnicianList",
                meta: {
                    breadcrumb: ["技师管理"]
                },
                component: () => import("../views/Technician/TechnicianList.vue"),
            },
            {
                path: "/car/list",
                name: "CarList",
                meta: {
                    breadcrumb: ["车辆管理"]
                },
                component: () => import("../views/Car/CarList.vue"),
            },
            {
                path: "/location/list",
                name: "LocationList",
                meta: {
                    breadcrumb: ["定位管理"]
                },
                component: () => import("../views/Location/LocationList"),
            },
            {
                path: "/fee/list/:type",
                name: "FeeList",
                meta: {
                    breadcrumb: ["费用结算"]
                },
                component: () => import("../views/Fee/FeeList.vue"),
            },
        ]
    },
]