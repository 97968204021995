import {createApp} from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
// 解决element-plus默认语言为英文的问题
import "dayjs/locale/zh-cn"
import locale from "element-plus/lib/locale/lang/zh-cn"

const app = createApp(App)

app.use(router)
    .use(store)
    .use(ElementPlus, {locale})
    .mount("#app")
